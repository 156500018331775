import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

type DeepLinkerProps = {
  domain: string;
};

const AppUrlListener: React.FC<any> = ({ domain }: DeepLinkerProps) => {
  let history = useHistory();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {

      if (Capacitor.isNativePlatform()) {
        Browser.close();
      }

      //use domain to redirect to the correct page
      const slug = event.url
        .replace("https", "http")
        .replace("intent", "http")
        .split(domain.replace("https", "http"))[1];

      //redirect to the correct page
      history.push(slug);
    });
  }, []);

  return null;
};

export default AppUrlListener;
