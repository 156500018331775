import { useContext, useEffect } from "react";
import { callOutline, openOutline } from "ionicons/icons";
import { Text } from "../../components/ui";
import Button from "../../components/ui/Button";
import { getSupport } from "../../api/emergencies";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import Accordion from "../../components/ui/Accordion";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { warning, warningOutline } from "ionicons/icons";
import EmptyState from "../../components/ui/EmptyState";

type SupportProps = {
  id: string;
};

type SupportType = "mental" | "financial" | "charity" | "industry";

export const Support: React.FC<RouteComponentProps<SupportProps>> = ({
  match,
}) => {
  const { id } = match.params;
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const {
    data: supportData,
    state: { hasLiveErrored, haveBothLoaded },
  } = useCacheLiveData(getSupport, [id]);

  useEffect(() => {
    if (hasLiveErrored) {
      showDismissableSnackbar("Error retrieving new emergency support data.");
      return;
    }
  }, [supportData]);

  const supportButtons = (supportType: SupportType) => {
    return supportData
      ? supportData[supportType].map(
        (item: { [key: string]: string }, index: number) => {
          const phoneNumber = item[`support-${supportType}-phone-number`];
          const email = item[`support-${supportType}-website-email`];

          if (!phoneNumber && !email) return null;

          return (
            <IonGrid className="ion-no-padding">
              <IonRow key={index} className="margin-bottom-16">
                {phoneNumber && (
                  <IonCol size="6">
                    <Button
                      expand="block"
                      href={`tel:${phoneNumber}`}
                      icon={callOutline}
                      variant="secondary"
                    >
                      Call
                    </Button>
                  </IonCol>
                )}
                {email && (
                  <IonCol size="6">
                    <Button
                      expand="block"
                      onClick={() => window.open(email, "_blank")}
                      icon={openOutline}
                      iconPosition="end"
                    >
                      Website
                    </Button>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          );
        }
      )
      : [];
  };

  return (
    <>
      <div className="margin-y-32 margin-x-24">
        {supportData === undefined && haveBothLoaded && (
          <EmptyState
            {...(hasLiveErrored
              ? {
                icon: warning,
                header: "Offline Mode",
                body: "We are unable to retrieve emergency support page. Please check your internet connection.",
              }
              : {
                icon: warningOutline,
                body: "No support details available.",
              })}
          />
        )}
        {supportData === undefined && !haveBothLoaded && <FullscreenLoader />}
        {/* {typeof supportData === "undefined" && <FullscreenLoader />} */}
        {supportData === null && (
          <Text type="body" size="large" as="p">
            No support details available
          </Text>
        )}

        {supportData && (
          <>
            <div className="margin-bottom-32">
              <Text type="heading" size="large" as="h1">
                Hotline
              </Text>

              <Text type="body" size="large" as="p" className="margin-y-16">
                <span
                  dangerouslySetInnerHTML={{
                    __html: supportData.hotline["response-hotline-description"],
                  }}
                />
              </Text>

              <Button variant="secondary" icon={callOutline}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<a href='tel:${supportData.hotline["response-hotline-number"]}'>${supportData.hotline["response-hotline-number"]}</a>`,
                  }}
                />
              </Button>
            </div>

            {supportData.reimbursement.description && (
              <div className="margin-bottom-32">
                <Text type="heading" size="large" as="h1">
                  Reimbursement
                </Text>

                <Text type="body" size="large" as="p" className="margin-y-16">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: supportData.reimbursement.description,
                    }}
                  />
                </Text>
                {supportData.reimbursement["more-information"] && (
                  <Button
                    variant="secondary"
                    icon={openOutline}
                    iconPosition="end"
                    onClick={() =>
                      window.open(
                        supportData.reimbursement["more-information"],
                        "_blank"
                      )
                    }
                  >
                    Website
                  </Button>
                )}
              </div>
            )}

            {supportData.industry.length > 0 && (
              <div className="margin-y-32">
                <Text size="large" type="heading" as="h1">
                  Industry support
                </Text>
                <Accordion
                  split
                  items={supportData.industry}
                  buttons={supportButtons("industry")}
                />
              </div>
            )}

            {supportData.financial.length > 0 && (
              <div className="margin-bottom-32">
                <Text size="large" type="heading" as="h1">
                  Financial support
                </Text>
                <Accordion
                  className="margin-top-16"
                  split
                  items={supportData.financial}
                  buttons={supportButtons("financial")}
                />
              </div>
            )}

            {supportData.mental.length > 0 && (
              <div className="margin-bottom-32">
                <Text size="large" type="heading" as="h1">
                  Mental Health support
                </Text>
                <Accordion
                  className="margin-top-16"
                  split
                  items={supportData.mental}
                  buttons={supportButtons("mental")}
                />
              </div>
            )}

            {supportData.charity.length > 0 && (
              <div className="ion-margin-bottom">
                <Text size="large" type="heading" as="h1">
                  Charity
                </Text>
                <Accordion
                  className="margin-top-16"
                  split
                  items={supportData.charity}
                  buttons={supportButtons("charity")}
                />
              </div>
            )}

            {supportData.assistanceGuideLink && (
              <Button
                onClick={() =>
                  window.open(supportData.assistanceGuideLink, "_blank")
                }
                icon={openOutline}
                iconPosition="end"
                variant="secondary"
              >
                More info
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Support;
