import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Browser } from "@capacitor/browser";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Replay } from "@sentry/replay";
import { setupFirebase } from "./api/firebase";
import BottomSheet from "./components/ui/BottomSheet";
import { PushNotificationHandler } from "./components/PushNotificationHandler";
import { Auth } from "@aws-amplify/auth";
import { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_RELEASE } from "./env";
import { useEffect } from "react";
import DeepLinker from "./components/DeepLinker";
import {
  AddPropertyPage,
  AllEmergenciesPage,
  FormPage,
  LoginPage,
  PropertiesPage,
  PropertyPage,
  SettingsPage,
  NotificationsPage,
  SplashPage,
  SnswRedirect,
  EmergencyPage,
} from "./pages";

/* Theme typeface */
import "@fontsource-variable/public-sans/index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/agText.css";

import "leaflet/dist/leaflet.css";
// Site wide styles, because it seems that locally contained styles just render everywhere anyway
import "./App.scss";
import { SnackbarHandler } from "./components/SnackbarHandler";
import { Capacitor } from "@capacitor/core";

if (REACT_APP_SENTRY_DSN && REACT_APP_SENTRY_RELEASE) {
  Sentry.init(
    {
      dsn: REACT_APP_SENTRY_DSN,
      release: REACT_APP_SENTRY_RELEASE,
      dist: "0",
      //@ts-ignore
      integrations: [
        new BrowserTracing(),
        new Replay({
          maskAllText: false,
          maskAllInputs: true,
          unmask: ["*"],
          sessionSampleRate: 1,
        }),
      ],
      tracesSampleRate: 1.0,
    },
    SentryReact.init
  );
}

setupIonicReact({
  animated: true,
  backButtonText: "",
  rippleEffect: true,
  mode: "md",
});

const routes = [
  { path: "/", component: SplashPage, exact: true },
  { path: "/add-property", component: AddPropertyPage, exact: true },
  { path: "/add-property/:by", component: AddPropertyPage, exact: false },
  { path: "/emergencies", component: AllEmergenciesPage, exact: true },
  { path: "/emergency/:id", component: EmergencyPage, exact: false },
  { path: "/form/:id", component: FormPage, exact: false },
  { path: "/login", component: LoginPage, exact: true },
  { path: "/login/snsw", component: SnswRedirect, exact: true },
  { path: "/properties", component: PropertiesPage, exact: true },
  { path: "/property/:id", component: PropertyPage, exact: false },
  { path: "/settings", component: SettingsPage, exact: false },
  { path: "/notifications", component: NotificationsPage, exact: true },
];

const {
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
} = process.env;

setupFirebase();

type AppProps = {
  domain: string;
};

const App: React.FC<AppProps> = ({ domain }) => {
  // const [forceRoute, setForceRoute] = useState<string | undefined>(undefined);
  // const ionRouter = useIonRouter();

  const fullDomain = domain.startsWith("https://")
    ? domain
    : `https://${domain}`;

  useEffect(() => {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      window.open = (function (open) {
        return function (url, name, features) {
          if (url) {
            if (new URL(url).hostname.includes("amazoncognito")) {
              Browser.open({ url: url.toString() });
              return null;
            }
          }

          return open(url, name, features);
        };
      })(window.open);
    }

    const authOptions = {
      region: "ap-southeast-2",
      userPoolId: REACT_APP_COGNITO_USER_POOL_ID ?? "",
      userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID ?? "",
      oauth: {
        domain: `${REACT_APP_COGNITO_DOMAIN}.auth.ap-southeast-2.amazoncognito.com`,
        scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
        redirectSignIn: `${fullDomain}/login/snsw`,
        redirectSignOut: `${fullDomain}/login`,
        responseType: "code",
      },
    };
    Auth.configure(authOptions);
  }, []);

  return (
    <>
      <IonApp className="md">
        <IonReactRouter>
          <DeepLinker domain={domain} />
          <PushNotificationHandler />
          <IonRouterOutlet>
            <BottomSheet>
              <SnackbarHandler>
                {routes.map((route) => (
                  <Route {...route} key={route.path} />
                ))}
              </SnackbarHandler>
            </BottomSheet>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </>
  );
};

export default App;
