import { useEffect, useState } from "react";
import { isPlatform, IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import clsx from "clsx";
import "./DownloadAppAlert.scss";

type Props = {
  variant?: "default" | "floating";
};

const DownloadAppAlert: React.FC<Props> = ({ variant = "default" }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const isDismissed =
      localStorage.getItem("ag-app-download-prompt") === "true";
    setIsVisible(!isDismissed);
  }, []);

  const dismissAlert = () => {
    localStorage.setItem("ag-app-download-prompt", "true");
    setIsVisible(false);
  };

  if (!isVisible || isPlatform("capacitor")) return null;

  return (
    <div
      className={clsx(
        "ag-download-app-alert ion-padding margin-bottom-32 margin-top-16",
        {
          "ag-download-app-alert--floating ion-margin": variant === "floating",
        }
      )}
    >
      In order to access all the features of this service, including real-time
      alerts, download the BioResponse app for your{" "}
      <a href="/" target="_blank" aria-label="iOS Download Link">
        Apple iOS
      </a>{" "}
      or{" "}
      <a href="/" target="_blank" aria-label="Android Download Link">
        Google Android
      </a>{" "}
      device.
      <button onClick={dismissAlert} className="ion-padding" aria-label="Close">
        <IonIcon slot="icon-only" icon={close} size="small" />
      </button>
    </div>
  );
};

export default DownloadAppAlert;
